import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import {useEffect} from "react";
import ChangeLanguage from "../components/changeLanguage";

const CybercityCnPage = ({location}) => {

    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('_without-layout');
    }, []);

    return (
        <>
            <Helmet>
                <title>Cybercity | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <section className="installation-img-section">
                <img className="" src="/static/img/7_Cybercity.jpg" alt=""/>
            </section>

            <section className="site-section site-section--installation">
                <ChangeLanguage location={location}/>
                <div className="content-section" style={ { "--width": "72rem" } }>

                    <h2 className="content-section__title">CYBERCITY</h2>

                    <div className="content-section__text">
                        <p>由獲獎無數的荷蘭數碼藝術家 Julius Horsthuis 創作的10分鐘3D裸眼原創影片，站在超過9米闊投影牆前，整個身體就彷彿融入畫中一樣，一同進入未來的宇宙世界，另更邀得Texas 的作曲家David Levy作配樂，使影片更添震撼元素。而影像不時亦會出現香港的城市樣貌，用俯瞰的方式欣賞香港，視覺與聽覺達到巔峰，新鮮感十足！</p>
                    </div>
                    <br/>

                    <div className="green">
                        <h2 className="content-section__title">藝術家 — JULIUS HORSTHUIS</h2>
                        <div className="content-section__text">
                            <p>Julius Horsthuis 是一位來自阿姆斯特丹的世界著名電子藝術家。Julius 的作品以其開創性的分形藝術作品而聞名，曾在世界各地的著名的畫廊和藝術節上展出。</p>
                        </div>
                    </div>

                    <br/>

                    <div className="green">
                        <h2 className="content-section__title">作曲家 — DAVID LEVY</h2>
                        <div className="content-section__text">
                            <p>David Levy 是一位來自德克薩斯州的作曲家和聲音設計師。他為 Bethesda Softworks 和 HBO Max 等幾個著名工作室和電視網絡為各種遊戲和電影配樂。</p>
                        </div>
                    </div>

                    <MainSectionLogo className="content-section__logo" />

                </div>
            </section>
        </>
    )
}

export default CybercityCnPage;
